import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap";

// Connects to data-controller="users-list"
export default class extends Controller {
  static targets = ["successMessage", "reloadButton"];

  reloadFrame() {
    this.reloadButtonTarget.setAttribute("disabled", true);
    Turbo.visit(window.location.href, { frame: this.element.id });
  }

  displaySuccessMessage() {
    const toast = new Toast(this.successMessageTarget);
    toast.show();
  }
}
