import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="video-player"
export default class extends Controller {
  static values = { 
    isPlaying: Boolean,
    isMuted: Boolean
  }

  handleClick() {
    if (this.isPlayingValue) {
      this.isMutedValue = !this.isMutedValue;
    } else {
      this.element.play();
      this.isPlayingValue = true;
    }
  }

  handleVideoEnd() {
    this.isPlayingValue = false;
  }

  isMutedValueChanged() {
    this.element.muted = this.isMutedValue;
  }
}
