import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-row"
export default class extends Controller {
  static targets = ["qrCodeLink"];

  loadQrCode(e) {
    if (this.timer) return;
    if (e.target === this.qrCodeLinkTarget) return;

    this.timer = setTimeout(() => {
      clearTimeout(this.timer);
      delete this.timer;
    }, 500);
    
    this.qrCodeLinkTarget.click();
  }
}
