import { Controller } from "@hotwired/stimulus"
import { Button } from "bootstrap";

// Connects to data-controller="waiver-form"
export default class extends Controller {
  static targets = ["adultCheckbox", "childCheckbox", "submitButton"];

  get checkboxes() {
    return [this.adultCheckboxTarget, this.childCheckboxTarget];
  }

  checkboxChanged(e) {
    this.handleClickedCheckbox(e.target);
  }

  handleClickedCheckbox(clickedCheckbox) {
    const active = this.isActive(clickedCheckbox);

    if (active) {
      this.checkboxes.forEach(checkbox => {
        if (checkbox === clickedCheckbox || !this.isActive(checkbox)) return;
        
        this.toggleButton(checkbox);
      });
    }

    this.updateValidateButtonEnabled();
  }

  toggleButton(checkbox) {
    const button = Button.getOrCreateInstance(checkbox);
    button.toggle();
  }

  toggleInnerCheckbox(e) {
    if (this.checkboxes.includes(e.target)) return;
    
    const checkbox = e.currentTarget.querySelector("button");
    this.toggleButton(checkbox);
    this.handleClickedCheckbox(checkbox);
  }

  updateValidateButtonEnabled() {
    const hasEnabledCheckbox = this.checkboxes.some(checkbox => this.isActive(checkbox));

    if (hasEnabledCheckbox) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", true);
    }
  }

  isActive(checkbox) {
    return checkbox.getAttribute("aria-pressed") === "true";
  }
}
